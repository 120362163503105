.verticalTimeline {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.timelineItem {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.timelineIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.outerCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
}
.innerDot {
  border-radius: 50%;
}
.line {
  width: 1px;
}
.timelineContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -5px;
}
.timelineContent h4 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 24.38px;
  letter-spacing: 0%;
  color: var(--light-black);
}
.timelineContent p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 2px;
  color: var(--light-black);
}
