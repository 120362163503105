.assessmenttitle {
  font-weight: 600;
}
.details {
  display: flex;
  max-width: 60%;
  gap: 100px;
  margin-top: 30px;
  h5 {
    font-weight: 600;
    color: var(--darker-grey);
  }
  p {
    font-weight: 500;
    color: var(--darker-grey);
  }
  .skill {
    .allskill {
      flex-wrap: wrap;
      max-width: 250px;
      .skillListing {
        background: var(--primary-green);
        color: var(--primary-white);
        padding: 0px 4px;
        border-radius: 3px;
      }
    }
  }
}
.langExp {
  display: flex;
  gap: 100px;
  p {
    font-weight: 500;
  }
}
.skills {
  height: 355px;
  overflow: auto;
  margin-bottom: 54px;
  padding-top: 25px;
  padding-right: 20px;
  .questionRangeSection {
    margin-top: 0px;
    .range-wrap {
      margin: 0px auto 0rem;
    }
  }
  .skill-set {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .icontext {
      font-weight: 500;
      display: flex;
      align-items: center;
      width: 150px;
      overflow-wrap: anywhere;
      .icon {
        height: 25px;
        width: 25px;
        background-color: var(--primary-white);
        border: 1px solid var(--off-white);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: var(--primary-white);
        text-transform: uppercase;
        font-weight: 700;
        margin-right: 12px;
        flex-shrink: 0;
        .firstChar {
          color: var(--primary-black);
        }
      }
      .SkillNameChar {
        color: var(--primary-black);
        font-size: 14px;
      }
      .iconImage {
        height: 25px;
        width: 25px;
        border-radius: 50%;
      }
    }
    .rangeSelector {
      flex-grow: 1;
      margin-left: 30px;
    }
  }
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--very-light-grey);
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d5d5d5;
  }
  .numbering {
    font-weight: 700;
    color: var(--light-black);
  }
  .text {
    font-weight: 500;
    color: var(--light-black);
  }
}
.userImageCapture {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  h4 {
    margin-bottom: 30px !important;
  }
  .systemIcon {
    min-width: 40px;
    text-align: center;
  }
  .systemHeading {
    width: 100%;
  }
  .checkSec {
    min-width: 90px;
    text-align: center;
    .permissionDisabled {
      display: flex;
      align-items: center;
      border: 1px solid var(--primary-green);
      color: var(--primary-black);
      background: var(--primary-white);
      opacity: 1;
      padding: 10px 11px;
      font-size: 14px;
      font-weight: 500;
      .sr-only {
        margin-right: 6px;
      }
    }
    .groupBtnMid {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }
  .iconText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    gap: 20px;
    flex: 1;
  }
  .testCheckCommon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-radius: 10px;
    gap: 20px;
    p {
      width: 100%;
      height: 100%;
      background-color: var(--whitish-grey);
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-radius: 3px;
    }
  }
  .testCheck-1 {
    p {
      background-color: #e5fff6;
    }
  }
  .testCheck-2 {
    p {
      background-color: #f9caca;
    }
  }
}
.assestsChecking {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
  }
  .parent-assests {
    display: flex;
    gap: 35px;
    .assets {
      display: flex;
      align-items: center;
      gap: 15px;
      .btn {
        background: transparent;
        border: none;
      }
    }
  }
}
.imageCapture {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 300px;
  height: 300px;
  transform: translateX(200%);
  visibility: hidden;
}
.submitAssessment {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
  /* AttemptText.css */
  .attempt-text {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    color: #122529;
    /* Dark gray text */
    margin-top: 10px;
  }
  .highlight {
    font-weight: bold;
    color: #00cbaf;
    margin-left: 5px;
    margin-right: 5px;
  }
  .submitBtn-disable {
    height: 50px;
    border: none;
    background-color: var(--light-grey);
    border-radius: 4px;
    color: var(--primary-white);
    margin: 20px 0;
  }
  .submitBtn-enable {
    height: 50px;
    border: none;
    background-color: var(--primary-green);
    border-radius: 4px;
    color: var(--primary-white);
    margin: 20px 0;
  }
}
.intructionImageCapture {
  display: flex;
  flex-direction: column;
  align-items: center;
  .disableBtnLoader {
    display: flex;
    align-items: center;
    gap: 20px;
    .loader {
      width: 25px;
      height: 25px;
      border-top-color: var(--darker-grey);
    }
  }
  video {
    box-shadow: rgb(171 255 235) 0px 0px 15px;
    margin-bottom: 15px;
  }
  img {
    margin-bottom: 20px;
  }
  video,
  img {
    width: calc(100% - 200px);
  }
  button {
    min-width: 120px;
  }
  .assessmentCommonBtn {
    button {
      font-size: 16px;
    }
  }
}
.assessmentInstrunctioPopup {
  .instructionBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    font-weight: 600;
    color: var(--primary-black);
  }
}
.skillsListedPara {
  display: flex;
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
  }
}
.instructionPopupSection {
  .modal-footer {
    padding-top: 20px;
    padding-bottom: 0px;
  }
}
.attempCount {
  display: flex;
  justify-content: end;
  span {
    font-weight: 600;
  }
}
@media (max-width: 540px) {
  .skills .skill-set {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
    .icontext {
      width: 100%;
    }
  }
  .skills .skill-set .rangeSelector {
    width: 100%;
    padding: 0 15px;
    margin-top: 10px;
  }
}
@media (max-width: 430px) {
  .details {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .userImageCapture .testCheckCommon {
    flex-direction: column;
  }
  .userImageCapture .iconText {
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .userImageCapture .checkSec {
    align-self: end;
  }
}
@media (max-width: 430px) {
  .submitAssessment {
    flex-direction: column;
    gap: 0px;
    align-items: flex-end;
  }
}
@media (max-width: 500px) {
  .intructionImageCapture video,
  .intructionImageCapture img {
    width: 200px;
  }
}
.zIndexTop {
  z-index: 1000;
}
.internet-banner {
  .top-toaster-container {
    position: relative;
  }
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--warning-yellow);
    font-weight: 600;
    color: var(--light-white);
    font-size: 14px;
    padding: 15px;
    text-align: center;
    transition:
      top 0.5s ease,
      opacity 0.5s ease;
    opacity: 0;
    font-family: Montserrat;
  }
  .responsive-message {
    display: none;
  }
  .banner.show {
    top: 0;
    opacity: 1;
  }
}
@media (max-width: 1023px) {
  .internet-banner .responsive-message {
    display: block;
    font-size: 16px;
    color: #333;
    text-align: center;
    margin: 20px;
  }
  .marginTopOn {
    margin-top: 60px !important;
    transition: margin-top 0.5s ease;
  }
}
