.changePassContainer {
  padding: 0 !important;
  .formContainer {
    margin-top: 15px;
    text-align: center;
    padding: 30px;
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .formInstructionSec {
      display: flex;
      flex-direction: column;
    }
    .userSection {
      .userContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        .headingDiv {
          margin-bottom: 20px;
          .userHeadingApp {
            margin-bottom: 10px;
            font-family: Montserrat;
            font-size: 30px;
            font-weight: 700;
            line-height: 36.57px;
            color: var(--darker-grey);
          }
          .userHeading {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 400;
            line-height: 21.94px;
            color: var(--darker-grey);
          }
        }
        .passwordSec {
          width: 100%;
        }
        .newPasswordInput {
          font-size: 14px;
          padding: 10px 15px;
          font-style: normal;
          font-weight: 500;
          width: 100%;
          border: 1px solid var(--light-grey);
          border-radius: 3px;
          margin-top: 10px;
          &::placeholder {
            color: var(--light-grey);
            font-size: 12px;
          }
          &:focus {
            outline: 0;
            box-shadow: none;
          }
          &::placeholder {
            color: var(--light-grey);
            font-size: 12px;
          }
        }
        .errorMessage {
          text-align: left;
          display: flex;
          align-self: flex-start;
        }
        .assessmentCommonBtn {
          width: 100%;
        }
        .submitBtn {
          width: 100%;
          margin-top: 16px;
          font-family: Montserrat;
          font-size: 14px;
          border-radius: 3px;
        }
        .submitBtnCreatePassword {
          margin-top: 16px;
        }
      }
      .veryfiOtpSec {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .passwordInstructions {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      margin-top: 10px;
      div {
        list-style: none;
      }
    }
    .otpErrorSec {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 100%;
    }
  }
}
