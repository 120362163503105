.initiatedType {
  .typeHead {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .selectField {
      width: 250px;
    }
  }
}
.listingMain {
  min-height: calc(100vh - 332px);
  .assessmentListing {
    border: 1px solid var(--off-white);
    border-radius: 5px;
    margin: 10px;
    .listing {
      display: flex;
      justify-content: space-between;
      padding: 0px;
      @media (max-width: 1120px) {
        flex-direction: column;
      }
      .section-1 {
        display: flex;
        // width: 75%;
        gap: 25px;
        flex: 2.5;
        @media (max-width: 1120px) {
          flex-direction: column;
          gap: 15px;
        }
        .logo {
          @media (max-width: 1120px) {
            height: 150px;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 200px;
          background-color: var(--extreme-light-green);
          svg {
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background-color: var(--secondary-green);
          }
          p {
            margin-bottom: 0;
            margin-top: 10px;
            font-weight: 600;
            font-size: 14px;
          }
        }
        .content {
          padding: 30px 0;
          @media (max-width: 1120px) {
            padding-left: 20px;
          }
          .title {
            color: var(--light-black);
            font-size: 18px;
            font-weight: 600;
            word-break: break-all;
            .readMore {
              gap: 10px;
            }
          }
          .assesmentDetails {
            display: flex;
            gap: 70px;
            @media (max-width: 1120px) {
              flex-direction: column;
              gap: 20px;
            }
            .assessment-company {
              width: 200px;
              word-break: break-all;
            }
            p {
              margin-bottom: 0px;
            }
            .p-content {
              font-size: 16px;
            }
          }
          .skill {
            margin: 15px 0;
            p {
              margin-bottom: 8px;
            }
            .allskill {
              flex-wrap: wrap;
              @media (max-width: 768px) {
                gap: 10px;
              }
              .skillListing {
                background: var(--primary-green);
                color: var(--primary-white);
                padding: 4px 8px;
                border-radius: 3px;
              }
              .moreSkill {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                font-weight: 600;
                border-radius: 50%;
                border: 1px solid var(--primary-green);
              }
            }
          }
        }
      }
      .section-2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0.6;
        align-items: flex-end;
        padding: 30px;
        position: relative;
        .followupIcon {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
        @media (max-width: 768px) {
          align-items: flex-start;
        }
        .viewBtn {
          border-radius: 5px;
          background: var(--primary-green);
          color: var(--primary-white);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 12px 18px !important;
          border: 0;
          width: fit-content;
          min-width: 135px;
        }
      }
    }
  }
  .dataNotAvailable {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.assessmentListing:hover {
  box-shadow: 0 0 10px var(--primary-green);
}
.assessmentListingHead {
  .hide-scrollbar {
    /* For WebKit-based browsers */
    -webkit-overflow-scrolling: touch;
    /* Optional: For smooth scrolling */
    -webkit-scrollbar-width: none;
    /* Hide scrollbar */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    /* For Chrome, Edge, Safari */
    ::-webkit-scrollbar {
      display: none;
      /* Hide scrollbar */
    }
    /* For Internet Explorer and Edge */
    -ms-overflow-style: none;
    /* Hide scrollbar */
  }
  .paginationSec {
    box-shadow: rgba(100, 100, 111, 0.2) 0px -3px 2px 0px;
  }
  .listingSearch {
    margin: 15px 10px;
    .templateSearchField .templateSearchIconSec input.form-control {
      box-shadow: 0px 5px 25px 0px var(--border-shadow);
      border: 2px solid var(--url-input-grey);
    }
  }
  .listingTitle {
    h2 {
      color: var(--light-black);
      font-family: Montserrat;
      line-height: normal;
      font-style: normal;
      font-size: 26px;
      font-weight: 600;
      margin: 0 10px;
    }
  }
  .listingHead {
    display: flex;
    margin: 20px 10px;
    padding-top: 10px;
    .listingFilters {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .checkboxFilters {
        display: flex;
        cursor: pointer;
        html[dir="rtl"] & {
          gap: 10px;
        }
        .listingRadioCheckLabel {
          color: var(--light-black);
          font-family: Montserrat;
          line-height: normal;
          font-style: normal;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          position: relative;
          top: 3px;
          cursor: pointer;
        }
      }
    }
  }
}
.horizontalLine {
  border: 1px solid var(--off-white);
  margin-bottom: 21px;
}
.followUpChatPanel {
  .offcanvas-body {
    overflow-x: hidden;
  }
  .offcanvas-header {
    border-bottom: 1px solid var(--division-grey);
    background-color: var(--secondary-green);
    align-items: flex-start;
  }
  .offcanvas-title {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 21.94px;
    color: var(--light-black);
    margin-left: 70px;
    overflow-wrap: anywhere;
  }
  .userImageBorder {
    width: 60px;
    height: 60px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1019607843);
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 7px;
    left: 7px;
    svg,
    img {
      width: 50px;
      border: 1.5px solid var(--primary-white);
      border-radius: 50%;
      height: 50px;
      object-fit: cover;
    }
    svg {
      padding: 10px;
      object-fit: cover;
    }
  }
}