.followUpChat {
  width: 100%;
  height: 100%;
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .verticalBarContainer {
    display: flex;
    justify-content: space-between;
    .statusTimeline {
      svg {
        width: 12px;
        height: 12px;
      }
      .reverseArrow {
        svg {
          transform: rotate(180deg);
          transition: transform 0.3s ease;
        }
      }
    }
  }
}
.openStatusTimeline {
  .messageSection {
    min-height: calc(100% - 190px);
  }
}
.assessmentName {
  span {
    overflow-wrap: anywhere;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 22px;
    line-height: 26.82px;
    letter-spacing: 0%;
    text-align: center;
    color: var(--light-black);
  }
  .seperatorHalf {
    margin: 12px 0;
    hr {
      width: 25%;
      border: 0.8px solid var(--light-black);
    }
  }
}
.seperator {
  padding: 8px 0;
  hr {
    border: 0.8px solid var(--light-black);
  }
}
.messageSection {
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  min-height: calc(100% - 352px);
  .scrollUpLoader {
    display: flex;
    justify-content: center;
    .loader {
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: var(--very-light-grey);
    }
  }
}
.messagesList {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 10px;
}
.messageBubble {
  background: var(--very-light-grey);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  .senderName {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: 0%;
    color: var(--darker-grey);
  }
  .message {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px;
    line-height: 14.63px;
    letter-spacing: 0%;
    color: var(--darker-grey);
    overflow-wrap: anywhere;
  }
  .time {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 10px;
    line-height: 12.19px;
    letter-spacing: 0%;
    color: var(--light-black);
    margin-top: 10px;
    text-align: right;
  }
}
.user {
  background: var(--moderate-green);
  align-self: flex-end;
}
.messageInputContainer {
  position: sticky;
  .messageCount {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    font-family: Montserrat;
    color: var(--light-black);
    text-align: right;
  }
  .sendBtnLoader {
    .loader {
      width: 20px;
      height: 20px;
      margin: 0 10px;
    }
  }
  .messageInput {
    display: flex;
    align-items: center;
    background-color: var(--primary-white);
    border: 1px solid var(--light-grey);
    input {
      flex: 1;
      border-radius: 2px;
      border: none;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 12px;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
      &::placeholder {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        line-height: 19.5px;
        letter-spacing: 0%;
        color: var(--light-grey);
      }
    }
    svg {
      width: 20px;
      height: 20px;
      margin: 0 10px;
    }
  }
}