html,
body {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.hideContent {
  display: none;
}
.addDisableCss {
  pointer-events: none;
}
.errorMessage {
  font-size: 12px;
  color: #ff1919;
  font-weight: 600;
  margin-top: 5px;
}
body.modal-open {
  height: 100vh;
}

/* For Chrome, Safari and other WebKit-based browsers */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: var(--very-light-grey);
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
