.feedbackSection {
  width: 50%;
  margin: auto;
  text-align: center;
  margin-top: 60px;
  .feedbackHead {
    .feedbackTitle {
      color: var(--darker-grey);
    }
    span {
      color: var(--light-black);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    h6 {
      color: var(--darker-grey);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 20px;
    }
  }
  .feedbackContent {
    margin-top: 30px;
    text-align: start;
    label {
      color: var(--darker-grey);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
      display: block;
    }
    input {
      border-radius: 5px;
      border: 1px solid var(--light-grey);
      background: var(--primary-white);
      width: 100%;
      padding: 10px;
      &:focus {
        outline: none;
      }
    }
    .limitAccessCssOver {
      justify-content: space-between !important;
      .charLimitMsg {
        display: block !important;
      }
    }
    .limitAccess {
      display: flex;
      justify-content: end;
      height: 40px;
      align-items: center;
      .charLimitMsg {
        font-size: 12px;
        color: var(--red);
        font-weight: 600;
        margin-top: 5px;
        display: none;
      }
      .charLimit {
        text-align: end;
        font-size: 12px;
        font-weight: 600;
        margin-top: 5px;
      }
      .charLimitMsg {
        font-size: 12px;
        color: var(--red);
        font-weight: 600;
        margin-top: 5px;
      }
      .charLimit {
        text-align: end;
        font-size: 12px;
        font-weight: 600;
        margin-top: 5px;
      }
    }
    margin-bottom: 30px;
  }
  .feedbackBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .submitDisable {
      border-radius: 5px;
      cursor: no-drop;
      background: var(--light-grey);
      color: var(--primary-white);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 12px 28px;
      border: 0;
    }
  }
}
