.optOutPage {
  .confirmationSection {
    min-height: 65vh;
    .applicantOptOut {
      padding: 10px 70px;
      h2 {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 25px;
        color: var(--light-black);
      }
      .skillsName {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 12px;
      }
      h3 {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 22px;
        color: var(--light-black);
      }
      span {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        color: var(--darker-grey);
      }
      .buttonGroup {
        margin-top: 30px;
        display: flex;
        gap: 20px;
      }
      .stayBtn {
        padding: 8px 16px;
      }
      .optOutBtn {
        background: #f69a3f;
        padding: 8px 16px;
      }
      .stayBtn:hover {
        color: var(--primary-white);
      }
      .optOutBtn:hover {
        background: #f69a3f !important;
        color: var(--primary-white);
      }
    }
    .loaderSec {
      min-height: 65vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .otherDetails {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: 20px;
      .otherDetailTexts {
        span:nth-child(1) {
          font-size: 16px;
          font-weight: 700;
          line-height: 17px;
        }
        span:nth-child(2) {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    hr {
      margin: 0;
      margin-bottom: 20px;
    }
  }
  .base {
    visibility: hidden;
  }
}